import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1638e766"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "quotation-container" }
const _hoisted_2 = {
  key: 0,
  class: "options category-options"
}
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["onMousedown"]
const _hoisted_6 = { class: "collapse-icon" }
const _hoisted_7 = { class: "category-title" }
const _hoisted_8 = { class: "category-subtitle" }
const _hoisted_9 = {
  key: 0,
  class: "category-total"
}
const _hoisted_10 = { key: 0 }
const _hoisted_11 = {
  key: 2,
  class: "category-description"
}
const _hoisted_12 = ["onMousedown"]
const _hoisted_13 = { class: "top-section" }
const _hoisted_14 = { class: "product-top-row row" }
const _hoisted_15 = { class: "product-title" }
const _hoisted_16 = { class: "product-subtitle" }
const _hoisted_17 = { class: "top-right" }
const _hoisted_18 = {
  key: 0,
  class: "options options-product"
}
const _hoisted_19 = ["onMousedown"]
const _hoisted_20 = { class: "selected-container" }
const _hoisted_21 = {
  key: 0,
  class: "selected"
}
const _hoisted_22 = ["onMousedown"]
const _hoisted_23 = ["onMousedown"]
const _hoisted_24 = { class: "bottom-section" }
const _hoisted_25 = { class: "product-left" }
const _hoisted_26 = { class: "image-container" }
const _hoisted_27 = ["onMousedown"]
const _hoisted_28 = ["onMousedown"]
const _hoisted_29 = ["src"]
const _hoisted_30 = ["onMousedown"]
const _hoisted_31 = { class: "product-right" }
const _hoisted_32 = { class: "product-middle-row row" }
const _hoisted_33 = { class: "product-description" }
const _hoisted_34 = { class: "product-bottom-row row" }
const _hoisted_35 = { class: "bottom-left" }
const _hoisted_36 = { key: 0 }
const _hoisted_37 = { class: "bottom-right" }
const _hoisted_38 = {
  key: 0,
  class: "options options-product"
}
const _hoisted_39 = ["onMousedown"]
const _hoisted_40 = ["onClick"]
const _hoisted_41 = ["onClick"]
const _hoisted_42 = {
  key: 6,
  class: "frequency"
}
const _hoisted_43 = { class: "carousel" }
const _hoisted_44 = { class: "carousel-button-container-left" }
const _hoisted_45 = { class: "carousel-image-container" }
const _hoisted_46 = ["src"]
const _hoisted_47 = { class: "carousel-button-container-right" }
const _hoisted_48 = { class: "modal-description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_mdicon = _resolveComponent("mdicon")!
  const _component_SecondaryButton = _resolveComponent("SecondaryButton")!
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.quotation.categories, (category) => {
      return (_openBlock(), _createElementBlock("div", {
        key: category.id
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(["category", { 'qoutation-link': _ctx.isQuotationLink }])
        }, [
          (!_ctx.isQuotationLink)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("div", {
                  class: _normalizeClass(["options-button", { active: category.mandatory }]),
                  onClick: ($event: any) => (_ctx.toggleCategoryMandatory(category, $event))
                }, " Mandatory ", 10, _hoisted_3),
                _createElementVNode("div", {
                  class: _normalizeClass(["options-button", { active: category.multiple }]),
                  onClick: ($event: any) => (_ctx.toggleCategoryMultiple(category, $event))
                }, " Multiple ", 10, _hoisted_4)
              ]))
            : _createCommentVNode("", true),
          (category.details)
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: "category-alias",
                onMousedown: ($event: any) => (_ctx.toggleCollapse(category))
              }, [
                _createElementVNode("div", _hoisted_6, [
                  (!category.collapsed)
                    ? (_openBlock(), _createBlock(_component_mdicon, {
                        key: 0,
                        name: "chevronRight"
                      }))
                    : _createCommentVNode("", true),
                  (category.collapsed)
                    ? (_openBlock(), _createBlock(_component_mdicon, {
                        key: 1,
                        name: "chevronDown"
                      }))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("span", _hoisted_7, _toDisplayString(category.details.title), 1),
                _createElementVNode("span", _hoisted_8, _toDisplayString(category.details.subTitle), 1),
                (_ctx.categoryTotal(category))
                  ? (_openBlock(), _createElementBlock("span", _hoisted_9, [
                      _createTextVNode("Total category: " + _toDisplayString(_ctx.categoryTotal(category)) + " ", 1),
                      (_ctx.categoryTotal(category))
                        ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(_ctx.currency(category.products[0].price.currency)), 1))
                        : _createCommentVNode("", true)
                    ]))
                  : _createCommentVNode("", true)
              ], 40, _hoisted_5))
            : _createCommentVNode("", true),
          (category.collapsed)
            ? (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(category.details.description), 1))
            : _createCommentVNode("", true),
          (category.collapsed)
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 3 }, _renderList(category.products, (product) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: product.id
                }, [
                  _createElementVNode("div", {
                    class: _normalizeClass(["product", { 'product-selected': product.selected }]),
                    onMousedown: ($event: any) => (_ctx.selectProduct(category, product))
                  }, [
                    _createElementVNode("div", _hoisted_13, [
                      _createElementVNode("div", _hoisted_14, [
                        _createElementVNode("span", _hoisted_15, _toDisplayString(product.details.title), 1),
                        _createElementVNode("span", _hoisted_16, _toDisplayString(product.details.subTitle), 1),
                        _createElementVNode("div", _hoisted_17, [
                          (!_ctx.isQuotationLink)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                                _createElementVNode("div", {
                                  class: _normalizeClass(["options-button options-button-product", { active: product.mandatory }]),
                                  onMousedown: ($event: any) => (_ctx.toggleProductMandatory(category, product, $event))
                                }, " Mandatory ", 42, _hoisted_19)
                              ]))
                            : _createCommentVNode("", true),
                          _createElementVNode("div", _hoisted_20, [
                            (product.selected)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_21, _toDisplayString(product.selected ? "Selected" : "Select"), 1))
                              : _createCommentVNode("", true)
                          ]),
                          (!_ctx.token)
                            ? (_openBlock(), _createElementBlock("div", {
                                key: 1,
                                class: _normalizeClass(["recommended", { active: product.recommended }]),
                                onMousedown: ($event: any) => (_ctx.recommend($event, category, product))
                              }, " Recommended ", 42, _hoisted_22))
                            : _createCommentVNode("", true),
                          (_ctx.token && product.recommended)
                            ? (_openBlock(), _createElementBlock("div", {
                                key: 2,
                                class: _normalizeClass(["recommended-quotation", { active: product.recommended }]),
                                onMousedown: ($event: any) => (_ctx.recommend($event, category, product))
                              }, " Recommended ", 42, _hoisted_23))
                            : _createCommentVNode("", true)
                        ])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_24, [
                      _createElementVNode("div", _hoisted_25, [
                        _createElementVNode("div", _hoisted_26, [
                          (
                      product.product &&
                      product.product.images &&
                      product.product.images[product.product.activeIndex] &&
                      product.product.images.length > 1
                    )
                            ? (_openBlock(), _createElementBlock("div", {
                                key: 0,
                                onMousedown: ($event: any) => (_ctx.previousImage(product, $event)),
                                class: "image-button-container image-button-container-left"
                              }, [
                                _createVNode(_component_mdicon, {
                                  name: "chevronLeft",
                                  size: 20
                                })
                              ], 40, _hoisted_27))
                            : _createCommentVNode("", true),
                          (
                      product.product &&
                      product.product.images &&
                      product.product.images[product.product.activeIndex] &&
                      product.product.images[product.product.activeIndex].src
                    )
                            ? (_openBlock(), _createElementBlock("div", {
                                key: 1,
                                onMousedown: ($event: any) => (_ctx.openImage(product, $event))
                              }, [
                                _createElementVNode("img", {
                                  src: product.product.images[product.product.activeIndex].src
                                }, null, 8, _hoisted_29)
                              ], 40, _hoisted_28))
                            : _createCommentVNode("", true),
                          (
                      product.product &&
                      product.product.images &&
                      product.product.images[product.product.activeIndex] &&
                      product.product.images.length > 1
                    )
                            ? (_openBlock(), _createElementBlock("div", {
                                key: 2,
                                onMousedown: ($event: any) => (_ctx.nextImage(product, $event)),
                                class: "image-button-container image-button-container-right"
                              }, [
                                _createVNode(_component_mdicon, {
                                  name: "chevronRight",
                                  size: 20
                                })
                              ], 40, _hoisted_30))
                            : _createCommentVNode("", true)
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_31, [
                        _createElementVNode("div", _hoisted_32, [
                          _createElementVNode("div", _hoisted_33, _toDisplayString(product.details.description), 1)
                        ]),
                        _createElementVNode("div", _hoisted_34, [
                          _createElementVNode("div", _hoisted_35, [
                            (product.details.url)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_36, [
                                  _createVNode(_component_SecondaryButton, {
                                    label: "More info",
                                    class: "m-0",
                                    onClick: ($event: any) => (_ctx.goToShowMoreUrl(product.details.url))
                                  }, null, 8, ["onClick"])
                                ]))
                              : _createCommentVNode("", true)
                          ]),
                          _createElementVNode("div", _hoisted_37, [
                            (!_ctx.isQuotationLink)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_38, [
                                  _createElementVNode("div", {
                                    class: _normalizeClass(["options-button p-2", { active: product.multiple }]),
                                    onMousedown: ($event: any) => (_ctx.toggleProductMultiple(category, product, $event))
                                  }, " Multiple ", 42, _hoisted_39)
                                ]))
                              : _createCommentVNode("", true),
                            (product.multiple)
                              ? (_openBlock(), _createElementBlock("div", {
                                  key: 1,
                                  ref_for: true,
                                  ref: "numberChooser",
                                  class: "mr-1",
                                  onMousedown: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
                                }, [
                                  _createVNode(_component_InputNumber, {
                                    "data-id": product.id,
                                    class: "multiple-count",
                                    placeholder: "Number of products",
                                    modelValue: product.amount,
                                    "onUpdate:modelValue": ($event: any) => ((product.amount) = $event),
                                    inputId: "integeronly",
                                    showButtons: "",
                                    min: 1,
                                    onMousedown: ($event: any) => (_ctx.updateProductMultiple(category, product, $event))
                                  }, null, 8, ["data-id", "modelValue", "onUpdate:modelValue", "onMousedown"])
                                ], 544))
                              : _createCommentVNode("", true),
                            (product.price.price && _ctx.editingPrice !== product.id)
                              ? (_openBlock(), _createElementBlock("div", {
                                  key: 2,
                                  class: _normalizeClass(["price price-styling", { crossed: product.price.adjustedPrice, editable: !_ctx.isQuotationLink }]),
                                  onClick: ($event: any) => (_ctx.editPrice(product.id)),
                                  onMousedown: _cache[1] || (_cache[1] = ($event) => $event.stopImmediatePropagation())
                                }, _toDisplayString(product.price.price) + " " + _toDisplayString(_ctx.currency(product.price.currency)), 43, _hoisted_40))
                              : _createCommentVNode("", true),
                            (product.price.price && _ctx.editingPrice === product.id)
                              ? (_openBlock(), _createBlock(_component_InputNumber, {
                                  key: 3,
                                  modelValue: product.price.price,
                                  "onUpdate:modelValue": ($event: any) => ((product.price.price) = $event),
                                  type: "number",
                                  class: "price-edit-input",
                                  onMousedown: _cache[2] || (_cache[2] = ($event) => $event.stopImmediatePropagation()),
                                  onBlur: ($event: any) => (_ctx.editQuotationProductPrice($event, product.price))
                                }, null, 8, ["modelValue", "onUpdate:modelValue", "onBlur"]))
                              : _createCommentVNode("", true),
                            (product.price.adjustedPrice && _ctx.editingAdjustedPrice !== product.id)
                              ? (_openBlock(), _createElementBlock("div", {
                                  key: 4,
                                  class: _normalizeClass(["adjustedprice price-styling", { editable: !_ctx.isQuotationLink }]),
                                  onClick: ($event: any) => (_ctx.editAdjustedPrice(product.id)),
                                  onMousedown: _cache[3] || (_cache[3] = ($event) => $event.stopImmediatePropagation())
                                }, _toDisplayString(product.price.adjustedPrice) + " " + _toDisplayString(_ctx.currency(product.price.currency)), 43, _hoisted_41))
                              : _createCommentVNode("", true),
                            (product.price.adjustedPrice && _ctx.editingAdjustedPrice === product.id)
                              ? (_openBlock(), _createBlock(_component_InputNumber, {
                                  key: 5,
                                  modelValue: product.price.adjustedPrice,
                                  "onUpdate:modelValue": ($event: any) => ((product.price.adjustedPrice) = $event),
                                  type: "number",
                                  class: "price-edit-input",
                                  onMousedown: _cache[4] || (_cache[4] = ($event) => $event.stopImmediatePropagation()),
                                  onBlur: ($event: any) => (_ctx.editQuotationProductPrice($event, product.price))
                                }, null, 8, ["modelValue", "onUpdate:modelValue", "onBlur"]))
                              : _createCommentVNode("", true),
                            (
                        product.price.frequency &&
                        product.price.frequency.length &&
                        product.price.frequency !== 'SINGLE'
                      )
                              ? (_openBlock(), _createElementBlock("div", _hoisted_42, _toDisplayString(product.price.frequency === "SINGLE" ? "" : product.price.frequency), 1))
                              : _createCommentVNode("", true)
                          ])
                        ])
                      ])
                    ])
                  ], 42, _hoisted_12)
                ]))
              }), 128))
            : _createCommentVNode("", true)
        ], 2)
      ]))
    }), 128)),
    _createVNode(_component_Dialog, {
      visible: _ctx.productDialogVisible,
      "onUpdate:visible": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.productDialogVisible) = $event)),
      modal: true,
      draggable: false,
      header: "",
      style: { 'max-width': '700px' }
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_43, [
            _createElementVNode("div", _hoisted_44, [
              _createVNode(_component_mdicon, {
                name: "chevronLeft",
                size: 20,
                onClick: _ctx.previousCarouselImage
              }, null, 8, ["onClick"])
            ]),
            _createElementVNode("div", _hoisted_45, [
              _createElementVNode("img", {
                src: _ctx.activeProduct.product.images[_ctx.activeImageIndex].src
              }, null, 8, _hoisted_46)
            ]),
            _createElementVNode("div", _hoisted_47, [
              _createVNode(_component_mdicon, {
                name: "chevronRight",
                size: 20,
                onClick: _ctx.nextCarouselImage
              }, null, 8, ["onClick"])
            ])
          ]),
          _createElementVNode("div", _hoisted_48, _toDisplayString(_ctx.activeProduct.details.description), 1)
        ])
      ]),
      _: 1
    }, 8, ["visible"])
  ]))
}