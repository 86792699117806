
import SalesService from "@/services/SalesService"
import { computed, reactive, ref, defineComponent, watch, nextTick, onMounted } from "vue"
import { useRouter, useRoute } from "vue-router"
import { longToShortCurrency } from "@/helpers/CurrencyHelpers"
import { debounce } from "@/helpers/Debounce"
import InputNumber from "primevue/inputnumber"
import { useToast } from "primevue/usetoast"
import { state } from "@/utils/state"
import SecondaryButton from "../buttons/SecondaryButton.vue"
import ProductPrice from "@/models/ProductPrice"

export default defineComponent({
  props: {
    quotation: {
      type: Object,
    },
    token: {
      type: String,
    },
    quotationProductsApi: {
      type: Object,
      default: {
        loadImages: null,
      },
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    InputNumber,
    SecondaryButton,
  },
  emits: ["select-category"],
  setup(props: any, { emit }: any) {
    const salesService = new SalesService()
    const productDialogVisible = ref(false)
    const activeProduct = ref()
    const activeImageIndex = ref(0)
    let quotationCopy = null
    const imageCache: any = {}
    const editingPrice = ref(null)
    const editingAdjustedPrice = ref(null)
    const numberChooser = ref(null)
    const isQuotationLink = location.href.includes("quotationlink")

    props.quotationProductsApi.loadImages = loadImages

    const selectedProducts: any = computed(() => {
      return props.quotation.categories.reduce((acc: any, cat: any) => {
        if (cat.products.length) {
          ;[].push.apply(acc, cat.products)
        }
        return acc
      }, [])
    })

    //don't use this! it causes performance issues
    /*if (!props.token) {
      watch(
        selectedProducts,
        () => {
          loadImages()
        },
        { deep: true }
      )
    }*/

    watch(props.quotation, () => {
      debounce(loadImages, 1)
    })

    loadImages()

    function loadImages() {
      if (props.token) {
        //images are loaded in link component if uri token is present
        return
      }
      //load images when stuff changes
      props.quotation.categories.forEach((cat: any) => {
        cat.products.forEach((prod: any) => {
          if (
            prod?.product?.images.length &&
            prod?.product?.images.find((image: any) => !image.src)
          ) {
            prod.product.activeIndex = 0
            const promises = prod?.product?.images.map((image: any) => {
              return imageCache[image.id]
                ? new Promise((resolve) => resolve(imageCache[image.id]))
                : salesService.getProductImage(image.id)
            })

            Promise.all(promises).then((res) => {
              prod.product.images.forEach((image: any, index: number) => {
                imageCache[image.id] = res[index]
                image.src = res[index]
              })
            })
          }
        })
      })
      /*addedIds.length = 0
      categories.length = 0


      //deduplicate categories + remove useless data
      quotationCopy.categories.forEach((cat: any) => {
        if (cat.productCategory) {
          if (addedIds.indexOf(cat.productCategory.id) === -1) {
            addedIds.push(cat.productCategory.id)
            categories.push(cat)

            //keep references to original ones
            if (!cat.originalCategories) {
              cat.originalCategories = []
              cat.originalCategories.push(cat.productCategory.id)
            } else {
              cat.originalCategories.push(cat.productCategory.id)
            }
          }
        }
      })

      //add products to right category
      quotationCopy.categories.forEach((cat: any) => {
        cat.products.forEach((prod: any) => {
          categories.forEach((_filteredCat: any) => {
            if (
              cat.productCategory &&
              _filteredCat.originalCategories.indexOf(cat.productCategory.id) !== -1
            ) {
              const found = _filteredCat.products.find((_prod: any) => prod.id === _prod.id)
              if (!found) {
                _filteredCat.products.push(prod)
              }
            }
          })
        })
      })

      //get images per product
      */
    }

    function currency(cur: string) {
      return longToShortCurrency(cur)
    }

    function recommend(event: any, category: any, product: any) {
      if (!props.readOnly) {
        event.stopImmediatePropagation()
        if ((props.token as string)?.length) {
          return
        }

        salesService
          .updateQuotationProduct(
            category.id,
            product.id,
            {
              recommended: !product.recommended,
              mandatory: product.mandatory,
              multiple: product.multiple,
              selected: product.selected,
            },
            props.token as string
          )
          .then(() => {
            product.recommended = !product.recommended
          })
      }
    }

    const selectedCategory = ref()

    function selectProduct(category: any, product: any) {
      if (!props.readOnly) {
        let selectedProductsInThisCategory = reactive([])
        const newSelectedStatus = !product.selected
        const emitObj: any = {}
        selectedCategory.value = category
        emit("select-category", selectedCategory.value)

        category.products.forEach((product: any) => {
          if (product.selected) {
            selectedProductsInThisCategory.push(product)
          }
        })

        //product is mandatory and already selected so do nothing
        if (product.mandatory && product.selected) {
          return
        }

        //at least one must be selected in this category so do nothing
        if (category.mandatory && product.selected && selectedProductsInThisCategory.length === 1) {
          return
        }

        //can select only one in this category set all to false and continue
        if (!category.multiple && selectedProductsInThisCategory.length >= 1 && !product.selected) {
          selectedProductsInThisCategory.forEach((_product: any) => {
            if (_product !== product) {
              emitObj[_product.id] = { selected: false, amount: _product.amount }
              if ((props.token as string)?.length) {
                salesService
                  .selectPublicProduct(_product, false, props.token as string, {})
                  .then(() => {
                    _product.selected = false
                  })
              } else {
                salesService
                  .updateQuotationProduct(
                    category.id,
                    _product.id,
                    {
                      recommended: _product.recommended,
                      mandatory: _product.mandatory,
                      multiple: _product.multiple,
                      selected: false,
                    },
                    props.token as string
                  )
                  .then(() => {
                    _product.selected = false
                  })
              }
            }
          })
        }

        /*if (category.mandatory && product.selected && selectedProductsInThisCategory.length === 1) {
          toast.add({
            severity: "warn",
            summary: "Warning",
            detail: "You have to select at least 1 product for this category",
            life: 3000,
          })
          return
        }

        if (
          !category.multiple &&
          selectedProductsInThisCategory.length === 1 &&
          !product.selected
        ) {
          //SET EVERY PROD IN THIS CAT TO SELECTED FALSE IN NODEJS
          const emitObj = category.products.reduce((acc: any, cur: any) => {
            return (acc[cur.id] = { selected: false, amount: cur.amount })
          }, {})

          state.realtime.socket.emit("publish", {
            room: "quotationproducts",
            data: emitObj,
          })
          //SET EVERY PROD IN THIS CAT TO SELECTED FALSE IN STATE
          category.products.forEach((product: any) => {
            product.selected = false

            if (product.mandatory && product.selected) {
              toast.add({
                severity: "warn",
                summary: "Warning",
                detail: "Selected product is mandatory",
                life: 3000,
              })
            }
          })

          willSelect = true
        }

        //SINGLE
        //ONE ALREADY SELECTED IN THIS CATEGORY
        //CLICKED PRODUCT IS NOT SELECTED
        //
        if (
          !category.multiple &&
          selectedProductsInThisCategory.length === 1 &&
          !product.selected &&
        ) {
          category.products.forEach((product: any) => {
            product.selected = false
          })
        }

        if (product.mandatory && product.selected) {
          toast.add({
            severity: "warn",
            summary: "Warning",
            detail: "This product is mandatory",
            life: 3000,
          })
          return
        }*/

        //REALTIME
        state.realtime.socket.emit("publish", {
          room: "quotationproducts",
          data: Object.assign(
            {
              [product.id]: { selected: newSelectedStatus, amount: product.amount },
            },
            emitObj
          ),
        })

        if ((props.token as string)?.length) {
          const numberSelector = document.querySelector(`[data-id="${product.id}"]`)
          let numberSelectorInput,
            amount = 1

          if (numberSelector) {
            numberSelectorInput = numberSelector.querySelector(
              "input:first-child"
            ) as HTMLInputElement
            amount = +numberSelectorInput.value
          }

          salesService
            .selectPublicProduct(product, newSelectedStatus, props.token as string, {
              amount,
              multiple: true,
            })
            .then(() => {
              product.selected = newSelectedStatus
            })
        } else {
          salesService
            .updateQuotationProduct(
              category.id,
              product.id,
              {
                recommended: product.recommended,
                mandatory: product.mandatory,
                multiple: product.multiple,
                selected: newSelectedStatus,
              },
              props.token as string
            )
            .then(() => {
              product.selected = newSelectedStatus
            })
        }
      }
    }

    function previousImage(product: any, event: any) {
      event.stopImmediatePropagation()
      product.product.activeIndex =
        product.product.activeIndex === 0
          ? product.product.images.length - 1
          : product.product.activeIndex - 1
    }

    function nextImage(product: any, event: any) {
      event.stopImmediatePropagation()
      product.product.activeIndex =
        product.product.activeIndex === product.product.images.length - 1
          ? 0
          : product.product.activeIndex + 1
    }

    function openImage(product: any, event: any) {
      event.stopImmediatePropagation()
      productDialogVisible.value = true
      activeProduct.value = product
    }

    function categoryTotal(category: any) {
      return category.products.reduce((acc: any, cur: any) => {
        if (cur.selected) {
          let price = cur.price.adjustedPrice ? cur.price.adjustedPrice : cur.price.price
          if (cur.amount > 0 && cur.multiple) {
            price = price * cur.amount
          }

          if (price > 0 && (cur.price.frequency === null || cur.price.frequency === "SINGLE")) {
            acc += price
          }
        }

        return acc
      }, 0)
    }

    function toggleCollapse(category: any) {
      /*props.quotation.categories.forEach((cat: any) => {
        cat.collapsed = false
        state.realtime.socket.emit("publish", {
        room: "quotationcategories",
        data: {
          [cat.id]: { collapsed: false },
        },
      })
      })*/
      category.collapsed = !category.collapsed

      state.realtime.socket.emit("publish", {
        room: "quotationcategories",
        data: {
          [category.id]: { collapsed: category.collapsed },
        },
      })

      selectedCategory.value = category
      emit("select-category", selectedCategory.value)
    }

    function previousCarouselImage() {
      const imagesLength = activeProduct.value.product.images.length
      activeImageIndex.value =
        activeImageIndex.value === 0 ? imagesLength - 1 : activeImageIndex.value - 1
    }

    function nextCarouselImage() {
      const imagesLength = activeProduct.value.product.images.length
      activeImageIndex.value =
        activeImageIndex.value === imagesLength - 1 ? 0 : activeImageIndex.value + 1
    }

    function toggleProductMandatory(category: any, product: any, event: any) {
      if (!props.readOnly) {
        event.stopImmediatePropagation()
        salesService
          .updateQuotationProduct(
            category.id,
            product.id,
            {
              recommended: product.recommended,
              mandatory: !product.mandatory,
              multiple: product.multiple,
              selected: product.selected,
              amount: product.amount,
            },
            props.token as string
          )
          .then(() => {
            product.mandatory = !product.mandatory
          })
      }
    }

    function toggleProductMultiple(category: any, product: any, event: any) {
      if (!props.readOnly) {
        event.stopImmediatePropagation()
        salesService
          .updateQuotationProduct(
            category.id,
            product.id,
            {
              recommended: product.recommended,
              mandatory: product.mandatory,
              multiple: !product.multiple,
              selected: product.selected,
            },
            props.token as string
          )
          .then(() => {
            product.multiple = !product.multiple
          })
      }
    }

    function toggleCategoryMandatory(category: any, event: any) {
      if (!props.readOnly) {
        event.stopImmediatePropagation()
        salesService
          .updateQuotationCategory(category.id, {
            mandatory: !category.mandatory,
            multiple: category.multiple,
          })
          .then(() => {
            category.mandatory = !category.mandatory
          })
      }
    }

    function toggleCategoryMultiple(category: any, event: any) {
      if (!props.readOnly) {
        event.stopImmediatePropagation()
        salesService
          .updateQuotationCategory(category.id, {
            mandatory: category.mandatory,
            multiple: !category.multiple,
          })
          .then(() => {
            category.multiple = !category.multiple
            category.products.forEach((product: any) => {
              product.selected = false
            })
          })
      }
    }

    function updateProductMultiple(category: any, product: any, event: any) {
      event.stopImmediatePropagation()
      //REALTIME
      state.realtime.socket.emit("publish", {
        room: "quotationproducts",
        data: {
          [product.id]: { amount: product.amount, selected: product.selected },
        },
      })

      if (product.multiple) {
        salesService.updateQuotationProduct(
          category.id,
          product.id,
          {
            recommended: product.recommended,
            mandatory: product.mandatory,
            multiple: product.multiple,
            selected: product.selected,
            amount: product.amount,
          },
          props.token as string
        )
      }
    }

    function goToShowMoreUrl(url: string) {
      window.open(url, "_blank")
    }

    function editPrice(productId: string) {
      if (isQuotationLink) return
      editingPrice.value = productId
    }

    function editAdjustedPrice(productId: string) {
      if (isQuotationLink) return
      editingAdjustedPrice.value = productId
    }

    function editQuotationProductPrice(event: any, priceObj: ProductPrice) {
      nextTick(() => {
        salesService.editQuotationProductPrice(priceObj.id, priceObj).then(() => {
          editingPrice.value = null
          editingAdjustedPrice.value = null
        })
      })
    }

    return {
      quotationCopy,
      currency,
      recommend,
      selectProduct,
      previousImage,
      nextImage,
      openImage,
      categoryTotal,
      toggleCollapse,
      productDialogVisible,
      activeProduct,
      activeImageIndex,
      previousCarouselImage,
      nextCarouselImage,
      selectedProducts,
      toggleProductMandatory,
      toggleProductMultiple,
      toggleCategoryMandatory,
      toggleCategoryMultiple,
      isQuotationLink,
      updateProductMultiple,
      goToShowMoreUrl,
      editingPrice,
      editingAdjustedPrice,
      editPrice,
      editAdjustedPrice,
      editQuotationProductPrice,
    }
  },
})
